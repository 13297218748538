
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { ScrollComponent } from '@/assets/ts/components/_ScrollComponent';
import { MenuComponent } from '@/assets/ts/components/MenuComponent';
import { version } from '@/core/helpers/documentation';
import { asideMenuIcons } from '@/core/helpers/config';
import MainMenuConfig from './Menu';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'kt-menu',
  components: {},
  data() {
    return {
      MainMenuConfig: [
        {
          heading: 'menu',
          route: '/training-institute',
          pages: [
            {
              sectionTitle: 'Training Institute',
              route: '/association/trainning-institute',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
              fontIcon: 'bi-app-indicator',
              sub: [
                {
                  heading: 'Training Institute List',
                  route: '/training-institute/institute-search',
                },
                {
                  heading: 'Add Training Institute',
                  route: '/training-institute/new-institute',
                },
              ],
            },
            {
              sectionTitle: 'Add Training Target',
              route: '/association/trainning-target',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
              fontIcon: 'bi-app-indicator',
              sub: [
                {
                  heading: 'Add Training Target',
                  route: '/training-institute/institute-target',
                },
                // {
                //   heading: 'Pending Training Target',
                //   route: '/training-institute/pending-institute-target',
                // },
              ],
            },
          ],
        },
      ],
    };
  },
  async created() {
    await this.menucheck();
  },
  methods: {
    async menucheck() {
      let entity_type_id = VueCookieNext.getCookie('_seip_entity_type');
      console.log(entity_type_id);
      if (entity_type_id == 1010) {
        this.MainMenuConfig = [
          {
            heading: 'menu',
            route: '/training-institute',
            pages: [
              {
                sectionTitle: 'Training Institute',
                route: '/association/trainning-institute',
                svgIcon: 'media/icons/duotune/general/gen025.svg',
                fontIcon: 'bi-app-indicator',
                sub: [
                  {
                    heading: 'Training Institute List',
                    route: '/training-institute/institute-search',
                  },
                  {
                    heading: 'Add Training Institute',
                    route: '/training-institute/new-institute',
                  },
                ],
              },
              {
                sectionTitle: 'Training Target',
                route: '/association/trainning-target',
                svgIcon: 'media/icons/duotune/general/gen025.svg',
                fontIcon: 'bi-app-indicator',
                sub: [
                  {
                    heading: 'Add Training Target',
                    route: '/training-institute/institute-target',
                  },
                ],
              },
            ],
          },
        ];
      }
      if (entity_type_id == 1011) {
        this.MainMenuConfig = [
          {
            heading: 'menu',
            route: '/training-institute',
            pages: [
              {
                sectionTitle: 'Training Institute',
                route: '/association/trainning-institute',
                svgIcon: 'media/icons/duotune/general/gen025.svg',
                fontIcon: 'bi-app-indicator',
                sub: [
                  {
                    heading: 'Training Institute List',
                    route: '/training-institute/institute-search',
                  },
                ],
              },
              {
                sectionTitle: 'Training Target',
                route: '/association/trainning-target',
                svgIcon: 'media/icons/duotune/general/gen025.svg',
                fontIcon: 'bi-app-indicator',
                sub: [
                  {
                    heading: 'Add Training Target',
                    route: '/training-institute/institute-target',
                  },
                ],
              },
            ],
          },
        ];
      }
    },
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
